@import 'common.scss';

html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  /*position: absolute;*/
  height: 100%;
  width: 100%;

  font-family: 'CMU Typewriter Text (Bold)', 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  @include fullscreen();
}