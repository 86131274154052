@import 'common.scss';

.App {
    @include fullscreen();

    background-color: $light-background;
    color: $light-text-color;

    /* Fixed Buttons */
    .TopButtons {
        $button-gap: 6px;

        position: fixed;
        top: $button-gap;

        .Button {
            border: none;
            font-size: 19px;

            @media screen and (max-width: $small-screen-width) {
                font-size: 16px;
            }

            background-color: $light-background;
            color: $light-text-color;
            z-index: 999;

            &:not(:disabled) {
                cursor: pointer;

                &:not(:hover) {
                    opacity: 80%;
                }
            }

            &:disabled {
                opacity: 20%;
            }

            margin-right: $button-gap;
        }

        &.Left {
            left: $button-gap;
        }

        &.Right {
            right: $button-gap;
        }
    }
}

.App.DarkMode {
    background-color: $dark-background;

    /* Global buttons */
    .TopButtons .Button {
        background-color: $dark-background;
        color: $dark-text-color;
    }
}