@import '../common.scss';

.Game {
    @include fullscreen();

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .Anagrams {
        width: 100%;
        overflow: auto;
        flex: 1;

        display: grid;
        gap: 0 0;
        grid-auto-columns: min-content;
        grid-auto-rows: min-content;
        justify-content: center;

        /* Scroll Bar (Firefox) */
        scrollbar-color: $light-text-color $light-input;
        scrollbar-width: thin;

        /* Scroll Bar (Others) */
        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            background: $light-input;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $light-input;
            border-radius: 1px;
            border: solid $light-text-color;
        }
    }

    .Bottom {
        width: 100%;
        height: 180px;

        @media screen and (max-width: $medium-screen-width) {
            height: 130px;
        }

        @media screen and (max-width: $small-screen-width) {
            height: 110px;
        }

        .Row {
            flex: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
        }
    }
}

.DarkMode .Game {
    .Anagrams {
        /* Scroll Bar (Firefox) */
        scrollbar-color: $dark-text-color $dark-input;

        /* Scroll Bar (Others) */
        &::-webkit-scrollbar-track {
            background: $dark-input;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $dark-input;
            border: solid $dark-text-color;
        }
    }
}