@import '../common.scss';

.Menu {
    @include fullscreen;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    overflow: auto;

    /* -------------------- Game Title -------------------- */
    .GameTitle {
        flex: 0;

        cursor: default;

        margin-bottom: 64px;
        @media screen and (max-width: $medium-screen-width) {
            margin-bottom: 32px;
        }

        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

        /* Slightly Modified version of Word.Letter */
        .Letter {
            $letter-font-size: 48px;
            $letter-gap: 5px;
            @include borderedCharBox($letter-font-size, $letter-gap);

            $letter-medium-factor: 0.7;
            @media screen and (max-width: $medium-screen-width) {
                @include borderedCharBox($letter-medium-factor*$letter-font-size,
                                         $letter-medium-factor*$letter-gap);
            }

            $letter-small-factor: 0.5;
            @media screen and (max-width: $small-screen-width) {
                @include borderedCharBox($letter-small-factor*$letter-font-size,
                                         $letter-small-factor*$letter-gap);
            }

            color: $light-text-color;
        }
    }

    /* ----------------- Menu Section Title ----------------- */
    .MenuSection {
        width: 100%;
        text-align: center;
        font-size: 40px;
        margin: 16px 0;

        @media screen and (max-width: $medium-screen-width) {
            font-size: 24px;
            margin: 24px 0;
        }

        cursor: default;

        color: $light-text-color;
    }

    /* ----------------- Language Selection ----------------- */
    .LanguageSelection {
        flex: 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        justify-content: center;
    }

    /* ----------------- Game Type Selection ----------------- */
    .GameTypeSelection {
        flex: 0;

        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        margin: auto;

        align-items: center;
    }

    /* ---------------------- Start Game --------------------- */
    .StartGame {
        margin-top: 64px;
        color: $light-text-color;
        text-align: center;

        font-size: 16px;
        height: 64px;

        cursor: pointer;

        b {
            margin-left: 5px;
        }
    }

    /* ---------------------- Dummy --------------------- */
    .Dummy {
        flex: 1;
        min-height: 32px;
    }
}

.DarkMode .Menu {
    .GameTitle {
        .Letter {
            color: $dark-text-color;
        }
    }

    .MenuSection {
        color: $dark-text-color;
    }

    .StartGame {
        color: $dark-text-color;
        &.hide {
            color: $dark-background;
        }
    }
}
