@import '../common.scss';

.ScoreBoard {
    @include not-selectable();
    $scoreboard-font-size: 19px;
    $scoreboard-font-size-large: 25px;
    $scoreboard-small-factor: 0.8;
    $scoreboard-tiny-factor: 0.6;

    $top-buttons-width: 64px;

    padding-left: $top-buttons-width;
    padding-right: $top-buttons-width;

    width: calc(100% - 2*$top-buttons-width);
    flex: 0;

    cursor: default;

    text-align: center;
    font-size: $scoreboard-font-size;

    @media screen and (max-width: $small-screen-width) {
        font-size: $scoreboard-small-factor*$scoreboard-font-size;
    }
    @media screen and (max-width: $tiny-screen-width) {
        font-size: $scoreboard-tiny-factor*$scoreboard-font-size;
    }

    margin-top: 3px;

    min-height: $scoreboard-font-size-large + 14px;

    @media screen and (max-width: $small-screen-width) {
        min-height: $scoreboard-small-factor*(2*$scoreboard-font-size + $scoreboard-font-size-large) + 24px;
    }
    @media screen and (max-width: $tiny-screen-width) {
        min-height: $scoreboard-tiny-factor*(2*$scoreboard-font-size + $scoreboard-font-size-large) + 24px;
    }

    color: $light-text-color;

    div {
        display: inline-block;

        @media screen and (max-width: $small-screen-width) {
            display: block;
        }

        &.Time.Alarm {
            @keyframes timeAlarmLight {
                from {
                    color: $light-text-color;
                }

                to {
                    color: $light-bad;
                }
            }

            animation-name: timeAlarmLight;
            animation-duration: 0.4s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }

        &.RoundNumber {
            margin: 0 8px;
            font-size: $scoreboard-font-size-large;

            @media screen and (max-width: $small-screen-width) {
                font-size: $scoreboard-small-factor*$scoreboard-font-size-large;
            }

            /* Font-awesome Icon */
            svg {
                margin: 0 8px;

                $flag-icon-size: $scoreboard-font-size;
                font-size: $flag-icon-size;

                @media screen and (max-width: $small-screen-width) {
                    font-size: $scoreboard-small-factor*$flag-icon-size;
                }

                color: $light-text-color;
            }

            .Bar {
                @media screen and (max-width: $small-screen-width) {
                    display: none;
                }
            }
        }

        &.Score {}
    }
}

.DarkMode .ScoreBoard {
    color: $dark-text-color;

    div.Time.Alarm {
        @keyframes timeAlarmDark {
            from {
                color: $dark-text-color;
            }

            to {
                color: $dark-bad;
            }
        }

        animation-name: timeAlarmDark;
    }

    div.RoundNumber {
        svg {
            color: $dark-text-color;
        }
    }
}