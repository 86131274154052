@import '../common.scss';

.Word {
    flex: 0;
    display: flex;
    margin: 0 16px;

    @media screen and (max-width: $small-screen-width) {
        margin: 0 8px;
    }

    /* Cursor interaction */
    &.Guessed,
    &.Show {
        cursor: help;
    }

    opacity: 90%;

    &.Guessed:hover,
    &.Show:hover {
        opacity: 100%;
    }

    /* Individual Letter styling */
    .Letter {
        $letter-font-size: 16px;
        $letter-gap: 5px;
        @include borderedCharBox($letter-font-size, $letter-gap);
        margin-top: $letter-font-size;

        $letter-medium-factor: 0.7;

        @media screen and (max-width: $medium-screen-width) {
            @include borderedCharBox($letter-medium-factor*$letter-font-size,
                $letter-medium-factor*$letter-gap);
            margin-top: $letter-medium-factor*$letter-font-size;
        }

        $letter-small-factor: 0.5;

        @media screen and (max-width: $small-screen-width) {
            @include borderedCharBox($letter-small-factor*$letter-font-size,
                $letter-small-factor*$letter-gap);
            margin-top: $letter-small-factor*$letter-font-size;
        }

        color: $light-text-color;
    }

    &.Guessed .Letter {
        background-color: $light-good;

        @keyframes WordRevealLight {

            /* flash animation when guessed */
            from {
                background-color: $light-background;
            }

            to {
                background-color: $light-good;
            }
        }

        animation-name: WordRevealLight;
        animation-duration: 0.1s;
    }
}

.DarkMode .Word {
    .Letter {
        color: $dark-text-color;
    }

    &.Guessed .Letter {
        background-color: $dark-good;

        @keyframes WordRevealDark {

            /* flash animation when guessed */
            from {
                background-color: $dark-background;
            }

            to {
                background-color: $dark-good;
            }
        }

        animation-name: WordRevealDark;
        animation-duration: 0.2s;
    }
}