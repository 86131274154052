/* --------         FONT TYPE         -------- */
@font-face {
    font-family: 'CMU Typewriter Text';
    src: local('CMU Typewriter Text'), url(./fonts/cmuntt.ttf) format('truetype');
}

@font-face {
    font-family: 'CMU Typewriter Text (Bold)';
    src: local('CMU Typewriter Text (Bold)'), url(./fonts/cmuntb.ttf) format('truetype');
}

/* --------        COMMON USAGE       -------- */
@mixin fullscreen {
    min-height: 100%;
    height: 100%;
    max-height: 100%;

    min-width: 100%;
    width: 100%;
    max-width: 100%;
}

$tiny-screen-width: 400px;
$small-screen-width: 520px;
$medium-screen-width: 800px;

$opacity-unselected: 0.5;
$opacity-hover: 0.8;
$opacity-selected: 1;

$line-height-factor: 1.25;
$firefox--line-height-factor: 1.00;

@mixin not-selectable() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin charBox($font-size, $padding) {
    font-size: $font-size;
    min-width: $font-size;
    width: $font-size;
    height: $font-size;
    @include not-selectable();

    @supports (-moz-appearance:none) {
        line-height: $firefox--line-height-factor * $font-size;
    }

    line-height: $line-height-factor * $font-size;

    padding: $padding;

    text-transform: uppercase;
    text-align: center;
}

@mixin borderedCharBox($font-size, $padding) {
    @include charBox($font-size, $padding);
    margin: 0 $padding;
    border-style: solid;
    border-width: 0.32px;
    border-radius: 10%;
}

/* --------     LIGHT MODE COLOURS    -------- */

$light-background: #fafafa;
$light-text-color: #111111;
$light-good: #9acd32;
$light-bad: #a80000;
$light-input: #d3d3d3;

/* --------     DARK MODE COLOURS     -------- */

$dark-background: #222222;
$dark-text-color: #e0e0e0;
$dark-good: #466900;
$dark-bad: #d87777;
$dark-input: #414141;